<template>
    <v-container fluid >
        <Formulario :getParams="this.params"></Formulario>
    </v-container>
</template>

<script>
const Formulario = () => import("./components/Formulario.vue");
import Auth from "@/services/auth";
export default {
    name: "ParticipantesAdministradoresNuevo",
    data() {
        return {
            permisos: [],
            params: {
                persona_id: null,
                cat_tipo_persona_id: 2,
            }
        }
    },
    components: {
        Formulario
    },
    methods: {
        regresar() {
            this.$router.go(-1)
        }
    },
    created() {
        Auth.checkPermisos("/participantes/administradores").then((res) => {
            this.permisos = res;

            if(!this.permisos[2]) {
                this.regresar();
            }
        });
    }
}
</script>

<style>

</style>
